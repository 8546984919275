@font-face {
  font-family: 'Apercu Pro';
  src: url('./assets/fonts/Apercu-Light-Pro.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('./assets/fonts/Apercu-Regular-Pro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('./assets/fonts/Apercu-Bold-Pro.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Apercu Pro';
  src: url('./assets/fonts/Apercu-Black-Pro.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
