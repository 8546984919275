.datepicker.andriod {
  background-color: #1ed760;
}
.datepicker .datepicker-header {
  padding: 10px !important;
  height: 60px;
  line-height: 2em;
  font-size: 20px;
  background-color: #1ed760;
  color: #ffffff !important;
}
.datepicker.android .datepicker-header {
  /* color: #ffffff !important; */
  border-bottom: none !important;
  border-radius: 16px 16px 0px 0px;
}

.datepicker.android .datepicker-wheel {
  border-top: 1px solid #c7c7c5 !important;
  border-bottom: 1px solid #c7c7c5 !important;
  /* width: 250px; */
}
.datepicker.android .datepicker-navbar-btn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  margin-top: 20px;
  /* line-height: 22px; */
}
.datepicker.android .datepicker-navbar {
  border-top: none;
  background-color: #ffffff;
  flex-direction: row-reverse;
  margin-top: -5px;
}
.datepicker.android .datepicker-navbar-btn:nth-child(2) {
  border: 1px solid #1ed760;
  margin-right: 10px;
  margin-left: 20px;
  border-radius: 4px;
  margin-bottom: 30px;
  width: 50px;
  margin-top: 20px;
}
.datepicker .datepicker-content {
  background-color: #ffffff;
}
.datepicker.android {
  background-color: initial !important;
}
.datepicker.android .datepicker-navbar-btn:nth-child(1) {
  border-left: none;
  background-color: #1ed760;
  margin-right: 20px;
  margin-left: 10px;
  margin-bottom: 30px;
  border-radius: 4px;
  width: 50px;
}
.datepicker .datepicker-viewport {
  height: 158px !important;
}

/* input{
  color:#3D3D3B !important;
} */
.react-datepicker-popper{
  margin-left: 40%;
}

.react-datepicker__header {
   background-color: #1ed760 !important;
   border-bottom:none !important;
  } 

.react-datepicker__navigation--next {
  margin-top: 4px;
} 
.react-datepicker__navigation--previous {
  margin-top: 4px;
} 

.react-datepicker__current-month{
  color: white !important;
  display: none !important;
  padding: 10px !important;
}
.react-datepicker__day-names{
  margin-top: 10px !important;
  background-color: #ffffff !important;
  padding: 5px !important; 
}
.react-datepicker__day--selected{
  background-color: #2fc1e2 !important;
  border-radius: 0px !important;
  padding: 5px !important;
}
.react-datepicker__day--outside-month{
  pointer-events: none;
  visibility: hidden !important;
}
.react-datepicker__day--weekend {
  color: #7b7b7b !important;
}
.react-datepicker__month-select {
  background-color: #1ed760 !important;
  color: white !important;
  width: 85px !important;
  outline: none !important;
}
.react-datepicker__year-select {
  background-color: #1ed760 !important;
  color: white !important;
  width: 50px !important;
  outline: none !important;
}
.react-datepicker__month-select > option {
  background-color: #fff !important;
  color: #000 !important;
  width: 85px !important;
}
.react-datepicker__year-select > option {
  background-color: #fff !important;
  color: #000 !important;
  width: 50px !important;
}
.react-datepicker__month-select .react-datepicker__year-select :focus{
  border-color: #1ed760;
}
.react-datepicker__day-name, .react-datepicker__day {
  width: 1.9em !important;
  line-height: 13px !important;
  margin: 8.5px !important;
}
/* .react-datepicker {
  margin-left: -20% !important;
} */