.loaderOverlay {
    position: fixed;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 1060;
    top: 0px;
    bottom: 0;
  }
  
  .loaderOverlay .loader {
    top: 45%;
    /* left: 45%; */
    margin-left: calc(50% - 37px);
    margin-right: calc(50% - 38px);
    position: relative;
  }