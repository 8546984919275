@font-face {
  font-family: "LatoBold";
  src: local("Lato"), url("./Lato-Bold.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "LatoRegular";
  src: local("Lato"), url("./Lato-Regular.ttf") format("truetype");
  font-display: swap;
}



