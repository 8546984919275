@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./fonts/fonts.css";

html {
  font-family: 'LatoRegular';
  line-height: 1.5;
  color: #3D3D3B;
}

[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: +20px;
  height: 18px;
  width: 18px;
  accent-color: #1ED760;
  padding: 4px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

@layer utilities {
  @layer responsive {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
    .scrollbar::-webkit-scrollbar {
      width: 10px;
      height: 3px;
    }
    .scrollbar::-webkit-scrollbar-track {
      border-radius: 20px;
    }
    .scrollbar::-webkit-scrollbar-thumb {
      background: #1ED760;
      border-radius: 20px;
      border: 1px solid #1ED760;
    }
  }
}
input[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: full;
  height: 5px;
  background:#EDEDED ;
  border-radius: 5px;
  background-image:linear-gradient(270deg, #03F182 0%, #00BFB2 100%);
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background:  #1ED760;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background .3s ease-in-out;
border:2px solid #FFFFFF
}

input[type=range]::-webkit-slider-runnable-track  {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 42px !important;
  width: 300px;
  outline: none;
}
.disabledInput {
  -webkit-text-fill-color:#3D3D3B;
   opacity:1 !important;
}
.react-tel-input .form-control:disabled{
  -webkit-text-fill-color:#3D3D3B;
  opacity:1 !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toast {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 9999;
}

.bootstrap-basic {
  background: white;
}

/* Braintree Hosted Fields styling classes*/
.braintree-hosted-fields-focused {
  outline: none;
}

.braintree-hosted-fields-focused.is-invalid {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.hosted-field {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  display: inline-block;
  box-shadow: none;
  font-weight: 600;
  font-size: 14px;
  border-radius: 6px;
  border: 1px solid #dddddd;
  line-height: 20px;
  background: #fcfcfc;
  margin-bottom: 12px;
  background: linear-gradient(to right, white 50%, #fcfcfc 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 300ms ease-in-out;
}

.hosted-fields--label {
  font-family: courier, monospace;
  text-transform: uppercase;
  font-size: 14px;
  display: block;
  margin-bottom: 6px;
}

.button-container {
  display: block;
  text-align: center;
}

.button {
  cursor: pointer;
  font-weight: 500;
  line-height: inherit;
  position: relative;
  text-decoration: none;
  text-align: center;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  display: inline-block;
}

.button--small {
  padding: 10px 20px;
  font-size: 0.875rem;
}

.button--green {
  outline: none;
  background-color: #64d18a;
  border-color: #64d18a;
  color: white;
  transition: all 200ms ease;
}

.button--green:hover {
  background-color: #8bdda8;
  color: white;
}

/* .braintree-hosted-fields-focused {
  border: 1px solid #64d18a;
  border-radius: 1px;
  background-position: left bottom;
} */

/* .braintree-hosted-fields-invalid {
  border: 1px solid #ed574a;
} */

#cardForm {
  max-width: 50.75em;
  margin: 0 auto;
  padding: 1.875em;
}

.cardNumberr :focus {
  outline: none;
}

